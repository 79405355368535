import React, { useState, useRef, useEffect } from 'react';
import { auth } from './firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import FitmateLogo from './pictures/fitmatecoachlogo.svg';
import OtpInput from 'react-otp-input';
import Cookies from 'js-cookie';

const countryCodes = [
  { value: '+1', label: '+1' },
  { value: '+44', label: '+44' },
];

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope, Arial, sans-serif',
  },
});

const App = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [success, setSuccess] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [countryCode, setCountryCode] = useState(countryCodes[0].value);
  const recaptchaRef = useRef(null);
  const [phoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendActive, setIsResendActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [countdownActive, setCountdownActive] = useState(true);
  
  const storeUserUid = (uid) => {
    localStorage.setItem('userUID', uid);
    sessionStorage.setItem('userUID', uid);
    Cookies.set('userUID', uid);
  }

  const handleCountryChange = (event) => {
    setCountryCode(event.target.value);
  };

  const formatPhoneNumber = () => {
    if(countryCode === "+1") {
      return countryCode + ' (' + phoneNumber.slice(0, 3) + ')-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10);
    } else if (countryCode === "+44"){
      return countryCode + ' ' + phoneNumber.slice(0, 4) + ' ' + phoneNumber.slice(4, 10);
    }
  };


  const onPhoneNumberChange = (e) => {
    let cleaned = e.target.value.replace(/[^\d]/g, '');
    if (cleaned.startsWith('0')) {
      cleaned = cleaned.slice(1);
    }
    setPhoneNumber(cleaned);
  };


  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if ((countryCode === '+1' && phoneNumber.length !== 10) || (countryCode === '+44' && phoneNumber.length !== 10)) {
      setPhoneError(true);
      setIsLoading(false);
      return;
    } else {
      setPhoneError(false);
    }

    const appVerifier = new RecaptchaVerifier(recaptchaRef.current, {
      size: 'invisible',
    }, auth);

    try {
      const result =  await signInWithPhoneNumber(auth, countryCode + phoneNumber, appVerifier)
      setConfirmationResult(result);
      setStep(2);
      setIsLoading(false);
      setIsResendActive(true);
      setOtp(''); // Clear OTP input after submitting phone number
    } catch (error) {
      console.log("SMS not sent", error);
      setPhoneError(true);
      setIsLoading(false);
    }
  };

  const onSubmitOTP = async () => {
    if (otp.length === 6) {
      try {
        const result = await confirmationResult.confirm(otp);
        storeUserUid(result.user.uid);
        setSuccess(true);
        setOtpError('');
      } catch (error) {
        console.error('Error verifying OTP', error);
        setOtpError('Incorrect OTP');
      }
    }
  };

  const resendOTP = (e) => {
    e.preventDefault();
    if (!countdownActive) {
      setStep(1);
      setPhoneNumber('');
      setOtp(''); // Clear OTP input after resending OTP
      setConfirmationResult(null);
      setIsResendActive(false);
      setSuccess(false);
      setRemainingTime(60);
      setIsLoading(false);
      setCountdownActive(true);
      setPhoneError(false);
    }
  };

  useEffect(() => {
    onSubmitOTP();
  }, [otp]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    if (remainingTime === 0) {
      clearInterval(timer);
      setCountdownActive(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (phoneNumber !== '') {
      setPhoneError(false);
    }
  }, [phoneNumber]);
  
  
  return (
    <ThemeProvider theme={theme}>
      <Container
        className="container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img src={FitmateLogo} alt="Fitmate Logo" className="logo" style={{marginBottom: '50px'}} />
        
        <Grid container justify="center" alignItems="center">
        <Box
            sx={{
              width: '400px',
              height: '600px',
              bgcolor: "#F5F2EZ", // Change this to the color you want
              m: 'auto', // Set margin to auto to horizontally center the box
              border: 0,
              borderRadius: '30px', // Change this to adjust the roundness of the edges
              boxShadow: 20,
            }}
          >
        {step === 1 && (
          <Box
            component="form"
            onSubmit={onSignInSubmit}
            sx={{
              width: '100%', // Use 100% width inside the container
              marginTop: '40px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" align="center" fontWeight='700' lineHeight={1.2} marginLeft={'30px'} marginRight={'30px'}>
              To view your profile, enter your phone number<br /><br />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
                gap: '10px',
              }}
            >
              <FormControl sx={{ minWidth: '80px', marginLeft:'0px'}} size="small">
                <InputLabel id="demo-select-small-label">Country Code</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={countryCode}
                  label="Country Code"
                  onChange={handleCountryChange}
                >
                  {countryCodes.map((code) => (
                    <MenuItem key={code.value} value={code.value}>
                      {code.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                variant="outlined"
                required
                id="mobile"
                label="Mobile number"
                name="mobile"
                autoComplete="off"
                autoFocus
                value={phoneNumber}
                onChange={onPhoneNumberChange}
                error={phoneError}
                helperText={phoneError ? 'Phone number entered incorrectly.' : ''}
                size="small"
              />
            </Box>

            <div id="recaptcha-container" ref={recaptchaRef}></div>

            <Button
              type="submit"
              //fullWidth
              variant="contained"
              sx={{
                marginTop: "10px",
                background: 'linear-gradient(180deg, #FA9464 0%, #D56F3F 100%)',
                height: '60px',
                width: '350px',
                borderRadius: '50px',
                ':hover': {
                  backgroundColor: '#BF6D3E',
                },
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color='inherit'/> : <Typography variant="button" fontWeight="600">Continue</Typography>}
            </Button>
          </Box>
        )}

        {step === 2 && (
          <Box
            component="form"
            onSubmit={resendOTP}
            sx={{
              width: '100%', // Use 100% width inside the container
              marginTop: '40px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" align="center" fontWeight='700' lineHeight={1.2} marginLeft={'30px'} marginRight={'30px'}>
              OTP sent to {formatPhoneNumber()}<br /><br />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props, i) => <input {...props} autoFocus={i === 0} style={{
                  width: '35px',
                  height: '40px',
                  fontSize: '20px',
                  borderRadius: '7px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  marginRight: '3px',
                  marginLeft: '3px'
                }} />}
              />
            </Box>

            {otpError && (
              <Typography color="error">{otpError}</Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                margin: 2,
                background: countdownActive ? 'linear-gradient(180deg, #9e9e9e 0%, #9e9e9e 100%)' : 'linear-gradient(180deg, #FA9464 0%, #D56F3F 100%)',
                height: '60px',
                width: '350px',
                borderRadius: '50px',
                ':hover': {
                  backgroundColor: countdownActive ? '#9e9e9e' : '#BF6D3E',
                },
              }}
              disabled={countdownActive}
            >
              <Typography variant="button" fontWeight="600">Resend OTP</Typography>
            </Button>

            {isResendActive && remainingTime > 0 && (
              <Typography variant="body2" align="center" marginBottom="1em">
                Resend the OTP in {remainingTime} seconds.
              </Typography>
            )}

            {success && (
              <Typography variant="h6" align="center">Success</Typography>
            )}
          </Box>
        )}

          <Box sx={{ width: '100%', textAlign: 'left', marginTop: '15px'}}>
              <Typography variant="body2" fontSize='14px' fontWeight='600' marginTop={1} lineHeight={1.2} marginLeft={'30px'} marginRight={'30px'}>
                We'll never share your information without your permission.
              </Typography>
              <Typography variant="body2" fontSize='10px' lineHeight={1.5} marginLeft={'30px'} marginRight={'30px'}>
                By providing your email, you’ll get access to your profile as well as occasional marketing and other communications from Fitmate, which can be opted out of at any time. Terms & Conditions and Privacy Policy apply.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default App;